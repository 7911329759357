<template>
    <div class="w-screen h-screen flex flex-col login-bg justify-center ">
    <!-- <div style="" class="w-screen h-screen flex flex-col justify-center bg-gray-100 "> -->
      <!-- The login page card -->
      <div class="relative py-4 sm:max-w-xl sm:mx-auto">
        <div class="relative px-4 py-4 bg-white shadow-2xl border m-2" id="login-card">
          <div class="mx-auto" style="width: 22.4rem">
            <div class="justify-center mb-0.5 pt-10">
              <img src="../assets/egpaf-logo.jpg" class="object-fill pl-4 mx-auto" style="image-rendering: crisp-edges; height: 8.0rem;"/>
            </div>
              <div class="pb-6 text-base leading-6 text-gray-700 sm:text-lg sm:leading-7 ">
                <!-- <p class="font-bold text-size-login-title sm:leading-7 text-emerald-600 opacity-75">WORKFLOW</p> -->
              </div>
              <div class="mb-0 space-y-6 px-5">
                <div class="px-1">
                  <label for="email" class="block text-size font-medium text-gray-600 text-left">Email</label>
                  <div class="mt-1">
                    <input
                      v-model="username"
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter Email"
                      @keyup.enter="login"
                      class="w-full border py-3 px-2 text-size shadow-sm focus:ring-emerald-600 focus:ring-2 focus:outline-none bg-gray-100"/>
                  </div>
                </div>
                <div class="px-1">
                  <label for="email" class="block text-size font-medium text-gray-600 text-left">Password</label>
                  <div class="mt-1">
                    <input
                      v-model="password"
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="password"
                      placeholder="Enter Password"
                      @keyup.enter="login"
                      class="w-full border py-3 px-2 text-size shadow-sm focus:ring-emerald-600 focus:ring-2 focus:outline-none bg-gray-100"/>
                  </div>
                </div>
                <div class="px-1 pt-2 pb-6">
                  <button
                    id="box-shadow"
                    @click="login"
                    class="w-full flex justify-center py-3 px-4 border border-transparent  shadow-md text-sm text-size font-medium
                          text-white mb-4 bg-emerald-600 hover:bg-emerald-500  focus:outline-none hover:-translate-y-0.5 transform transition ">
                      Login
                  </button>
                  <div class=" bg-gray-300 h-1 w-full opacity-70 mb-4" style="height: 1.2px;"></div>
                  <div class="flex mx-auto cursor-pointer text-size items-center">
                    <div @click="msalLogin()" class="mx-auto flex space-x-1 items-center hover:bg-gray-50 px-10 py-2.5 border rounded-sm">
                      <img class="h-5 w-5" src="../assets/microsoft.png"/>
                      <span class="text-size">Login With Microsoft</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- the loader modal -->
      <Loader ref="loaderRef"/>
      <!-- the message loader -->
      <Message :title="title" :message="loginMessage" ref="messageRef"/>
    </div>
</template>

<script>
  import { ajax } from 'rxjs/ajax'
  import { retry } from 'rxjs'
  import Loader from './modals/Loader'
  import Message from './modals/Message'
  import { PublicClientApplication } from '@azure/msal-browser'

  export default {
    name: 'LoginComponent',
    components: {
      Loader,
      Message
    },
    data: () => {
      return{
        title: 'Login Error',
        loginMessage: 'You cannot login please check your credentials.',
        username: '',
        password: '',
        BASE_URL: process.env.VUE_APP_BASE_URL,
      }
    },
    mounted () {
      this.$store.commit('mutateEmployee', null)
      this.$store.commit('mutateActiveListItemIndex', null)
    },
    methods: {
      // msal login
      async msalLogin () {
        try {
          this.$refs.loaderRef.openModal()
          const clientId = process.env.VUE_APP_CLIENT_ID
          const tenantId = process.env.VUE_APP_TENANT_ID
          const msalConfig = {
            auth: {
              clientId: clientId, // Replace with your application's Client ID
              authority: `https://login.microsoftonline.com/${tenantId}`, // Replace with your tenant ID 
              redirectUri: `https://moztravel.pedaids.org/`, // Replace with your application's redirect URI
            },
            cache: {
              cacheLocation: "sessionStorage", // Configure where your cache will be stored
              storeAuthStateInCookie: false, // Set to "true" if needed for specific scenarios
            }
          };

          const pca = new PublicClientApplication(msalConfig);

          await pca.initialize()

          const loginRequest = {
            scopes: ["https://graph.microsoft.com/.default"] // Replace with your desired scopes
          };
          const response = await pca.loginPopup(loginRequest);
          const token = response.accessToken
          this.loginDecoder(token)

        } catch (error) {
          console.log(error)
          this.$refs.loaderRef.closeModal()
        }
      },
      login () {
        if (this.username !== '' && this.password !== '') {
            this.$refs.loaderRef.openModal()
            const observable = ajax({
              url: this.BASE_URL + '/admin/login',
              method: 'POST',
              body: {
                email: this.username.trim(),
                password: this.password.trim()
              }
            })
            // .pipe(
            //   retry(1)
            // )
    
            const observer = {
              next: (response) => {
                const data = response.response[0]
                // console.log(data)
                this.$store.commit('mutateEmployee', data)
                this.$store.commit('mutateActiveListItemIndex', 0)
                
                // move to the next page
                this.$router.push('/admin-home')
              },
              error: (error) => {
                // // close the loader modal
                // getting the error
                const status = error.status
                if (status === 401) {
                  // try the employee login
                  this.employeeLogin()
                } else {
                  this.$refs.loaderRef.closeModal()
                  this.loginMessage = 'Failed to login, please check your internet connection...'
                  // open the error modal
                  this.$refs.messageRef.openModal()
                }
              },
              complete: () => {
                this.$refs.loaderRef.closeModal()
              }
            }
    
            observable.subscribe(observer)
        } else {
          this.loginMessage = 'You cannot login with empty credentials...'
          this.$refs.messageRef.openModal()
        }
      },
      employeeLogin () {
        const observable = ajax({
          url: this.BASE_URL + '/employee/login',
          method: 'POST',
          body: {
            email: this.username,
            password: this.password
          }
        })
        .pipe(
          retry(1)
        )

        const observer = {
          next: (response) => {
            const data = response.response[0]
            this.$store.commit('mutateEmployee', data)
            this.$store.commit('mutateActiveListItemIndex', 0)
            // move to the next page
            this.$router.push('/employee-home')
          },
          error: (error) => {
            // getting the error
            const status = error.status
            // error check the connection
            this.$refs.loaderRef.closeModal()
            if (status === 401) {
              // open the error modal
              this.loginMessage = 'Failed to login, please check your login credentials...'
            } else {
              // open the error modal
              this.loginMessage = 'Failed to login, please check your internet connection...'
            }
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
      },
      loginDecoder (token) {
        const observable = ajax({
          url: this.BASE_URL + '/employee/loginDecoder',
          method: 'POST',
          body: {
            token: token
          }
        })
        .pipe(
          retry(1)
        )

        const observer = {
          next: (response) => {
            const data = response.response[0]
            this.$store.commit('mutateEmployee', data)
            this.$store.commit('mutateActiveListItemIndex', 0)
            // move to the next page
            this.$router.push('/employee-home')
          },
          error: (error) => {
            // getting the error
            const status = error.status
            // error check the connection
            this.$refs.loaderRef.closeModal()
            if (status === 401) {
              // open the error modal
              this.loginMessage = 'Failed to login, please check your login credentials...'
            } else {
              // open the error modal
              this.loginMessage = 'Failed to login, please check your internet connection...'
            }
            this.$refs.messageRef.openModal()
          },
          complete: () => {
            this.$refs.loaderRef.closeModal()
          }
        }

        observable.subscribe(observer)
      }
    }
  }
</script>